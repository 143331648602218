@import "../../style/colors";

.ImageFormatSelect {
  margin-bottom: 20px;

  select {
    width: 100%;
    height: 38px;
    text-indent: 6px;
    display: block;
    background: $white;
    border: 1px solid $grey;
    font-size: inherit;
    line-height: inherit;
    margin-top: 10px;

    &:hover {
      cursor: pointer;
    }
  }
}