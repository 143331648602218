@import '../../style/colors';

.ImageResolutionPicker {
  display: flex;
  align-items: center;
  margin: 10px 0 35px;

  input {
    width: 137px;
    border: 1px solid $grey;
    font-size: inherit;
    line-height: inherit;
    padding: 7px 15px;
    text-align: right;
  }

  .icon-small.icon-close {
    margin: 7px;
    width: 10px;
    height: 10px;
  }
}