@import '../../style/colors';
@import '../../style/style';

$max-image-dimension-preview-lg: 550px;
$max-image-dimension-preview-sm: 300px;

.ImageResizer {

  button {
    border: none;
    padding: 0;

    &:hover {
      cursor: pointer;
    }
  }

  position: fixed;
  z-index: 2;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  background: $white-transparent;
  display: flex;
  justify-content: center;
  align-items: center;

  .container {
    position: relative;
    z-index: 3;
    margin: 18px 32px;
    padding: 80px 50px 100px;
    box-shadow: 0 1px 3px $light-grey;
    background: $white;

    display: flex;
    justify-content: center;

    @media (max-width: $small-device) {
      flex-direction: column;
    }

    .icon-large.icon-close-thin {
      position: absolute;
      top: 19px;
      right: 21px;
    }

    .image-container {
      width: $max-image-dimension-preview-lg;
      height: $max-image-dimension-preview-lg;   

      @media (max-width: $medium-device) {
        width: $max-image-dimension-preview-sm;
        height: $max-image-dimension-preview-sm;
      }

      display: flex;
      align-items: center;   
      justify-content: center;

      img {
        max-width: $max-image-dimension-preview-lg;
        max-height: $max-image-dimension-preview-lg;

        @media (max-width: $medium-device) {
          max-width: $max-image-dimension-preview-sm;
          max-height: $max-image-dimension-preview-sm;
        }
      }
    }

    .download-options {
      flex-direction: column;
      margin-left: 35px;

      @media (max-width: $small-device) {
        margin-left: 0;
      }

      font-size: 16px;
      line-height: 22px;
      color: $text-black;
      width: 300px;

      .title {
        font-size: 24px;
        font-weight: 600;
        line-height: 33px;
        color: $text-grey;
        margin: 15px 0 40px;

        @media (max-width: $small-device) {
          text-align: center;
        }
      }

      .button-group {
        margin-bottom: 30px;

        button {
          border-radius: 4px;
          width: 50%;
          height: 34px;
          background: $button-color;
          font-size: inherit;
          line-height: inherit;

          &.active {
            background: $button-color-active;
            color: $white;
          }
        }
      }

      button[type=submit] {
        border-radius: 4px;
        background: #5EC97F;
        font-weight: 600;
        font-size: inherit;
        line-height: inherit;
        color: $white;
        width: 100%;
        padding: 14px 0;
      }
    }
  }
}