$white: #FFFFFF;
$white-transparent: rgba(255,255,255,0.9);
$semi-white: #FAFAFA;
$semi-gray: rgba(0, 0, 0, 0.12);
$grey: #AAAAAA;
$darker-grey: #ADADAD;
$black: #333333;

$transparent: rgba(0,0,0,0);
$semi-transparent: rgba(0, 0, 0, 0.1); 
$light-grey: rgba(0, 0, 0, 0.16); 

$text-grey: #707070;
$text-black: #555555;

$button-color: #EFEFEF;
$button-color-active: #5E8BC9;
