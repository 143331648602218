@import '../../style/colors';
@import '../../style/style';

.SearchBar {
  background-color: $white;
  box-shadow: 0 1px 6px $semi-gray;
  position: relative;
  margin-bottom: 30px;

  .icon-search {
    position: absolute;
    top: 3px;
    left: 3px;
    padding: 15px;
    width: 50px;
    height: 50px;
  }

  input {
    font-size: 16px;
    line-height: 22px;
    width: 550px;
    color: $black;
    background-color: $white;
    border: none;    
    padding: 17px 46px 17px 53px;

    @media (max-width: 650px) {
      width: 100%;
    }
  }

  .clear-searchtext-button {
    position: absolute;
    top: 22px;
    right: 18px;
    width: 12px;
    height: 12px;
    background:
        linear-gradient(45deg, $transparent 0%,$transparent 43%, $darker-grey 45%, $darker-grey 55%,$transparent 57%,$transparent 100%),
        linear-gradient(135deg, $white 0%,$white 43%, $darker-grey 45%, $darker-grey 55%,$white 57%,$white 100%);

    &:hover {
      cursor: pointer;
    }
  }

  .icon-close {
    position: absolute;
    top: 22px;
    right: 18px;
  }
  
}