@import 'colors';

$medium-device: 992px;
$small-device: 768px;

.icon {
  &:hover {
    cursor: pointer;
  }

  &-small {
    width: 12px;
    height: 12px;
  }

  &-large {
    width: 27px;
    height: 27px;
  }

  &-close {
    background:
        linear-gradient(45deg, $transparent 0%, $transparent 43%, $darker-grey 45%, $darker-grey 55%, $transparent 57%, $transparent 100%),
        linear-gradient(135deg, $transparent 0%, $transparent 43%, $darker-grey 45%, $darker-grey 55%, $transparent 57%, $transparent 100%);

    &-thin {
      background:
          linear-gradient(45deg, $transparent 0%, $transparent 47%, $darker-grey 48%, $darker-grey 52%, $transparent 53%, $transparent 100%),
          linear-gradient(135deg, $transparent 0%, $transparent 47%, $darker-grey 48%, $darker-grey 52%, $transparent 53%, $transparent 100%);
    }
  }
}