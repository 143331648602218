@import 'style/colors';

html,
body {
  padding: 0;
  margin: 0;
  font-family: "Open Sans";
}

* {
  box-sizing: border-box;
  outline: none;
  font-family: inherit;
}

::placeholder { /* Firefox, Chrome, Opera */ 
  color: $grey; 
} 

:-ms-input-placeholder { /* Internet Explorer 10-11 */ 
  color: $grey; 
} 

::-ms-input-placeholder { /* Microsoft Edge */ 
  color: $grey; 
}


.App {
  min-height: 100vh;
  background: url(./kraken-logo.svg) no-repeat center $semi-white;
  background-size: 420px;

  padding: 36px 32px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  font-size: 16px;
  color: $grey;
}


