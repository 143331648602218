@import '../../style/colors';

.SearchResults {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  align-content: center;

  @media (max-width: 503px) {
    justify-content: center;
  }

  p {
    color: $text-grey;
    font-size: 16px;
    line-height: 22px;
    font-weight: 600;
    margin: 12px auto 6px;

    &.no-results {
      font-size: 16px;
      font-weight: normal;
      text-indent: 20px;
    }
  }

  .search-result-container {
    box-shadow: 0 1px 3px $semi-transparent;
    max-width: 200px;
    margin: 10px;
    padding: 10px;
    background-color: $white;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;

    &:hover{
      cursor: pointer;
    }

    .image-container {
      width: 180px;
      height: 180px;   
      display: flex;
      align-items: center;  
      justify-content: center; 

      img {
        max-width: 180px;
        max-height: 180px;
      }
    }
  }
}